import {IsObject} from "~/utils/tsUtils";

let apiBaseUrl: string | undefined = undefined
let apiGraphQLUrl: string | undefined = undefined

if (typeof window !== "undefined") {
  // we are in the Browser
  if (typeof window?.env?.API_BASE_URL === "string") {
    // window.env is set in root.jsx
    //  ENV: { API_BASE_URL: process.env.API_BASE_URL, NODE_ENV: process.env.NODE_ENV, }
    apiBaseUrl = window.env.API_BASE_URL
    apiGraphQLUrl = window.env.API_GRAPHQL_URL
  } else {
    throw new Error("Couldn't get API_BASE_URL from window.env");
  }
} else {
  // we are on the Server
  if (process.env.API_BASE_URL) {
    apiBaseUrl = `${process.env.API_BASE_URL}/cms`
    apiGraphQLUrl = `${process.env.API_BASE_URL}/graphql`
  }

  if (!apiBaseUrl && process.env.NODE_ENV === "production") {
    throw new Error("Environment variable API_BASE_URL is not defined or empty string");
  }
}

export const config = {
  KPTN_KEY: "6q7QNKy-oIgk-IMuWisJ-jfN7s6",
  API_BASE_URL: apiBaseUrl,
  API_GRAPHQL_URL: apiGraphQLUrl

};
