import { useLocation } from "@remix-run/react";
import _ from "lodash";
import { get } from "lodash";
import { commitSession, getCookieFromUserSession } from "../server/auth.server";
import { json } from "@remix-run/node";
import { getData } from "../server/api.server";
import { config } from "../config";

const editableLanguages = ["en", "de"];

export const getToken = async (request) => {
  try {
    return await getCookieFromUserSession(request);
  } catch (e) {
    return undefined;
  }
};

export const getIsDisabled = (lang) => !editableLanguages.includes(lang);

export const capitalizeFirstLetter = (string) =>
  string?.charAt(0).toUpperCase() + string?.slice(1);

export const dateIsInThePast = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today > date;
};

export const dateIsToday = (date) => {
  const today = new Date();
  return isSameDate(today, date);
};

const isSameDate = (date1, date2) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

// todo test with ingredients/id/brands with new or edit
export const getIdFromParams = (params) => {
  const path = params["*"];
  const id = path.split("/")[0];

  return path === "new" ? null : id;
};

const isIdPage = (id) => {
  const pattern = /^[a-fA-F0-9]{24}$/;
  return pattern.test(id);
};

export const getRouteInfo = () => {
  const { pathname } = useLocation();

  const segments = pathname.split("/");

  if (segments[1] === "ingredients") {
    if (segments[3] === "brands") {
      return { route: "brands", brandId: segments[4] };
    }
  }

  if (segments[1] === "retailers") {
    if (segments[3] === "stores") {
      return { route: "stores" };
    }
    if (segments[3] === "products") {
      return { route: "products" };
    }
  }

  if (segments[1] === "stepTemplates" && isIdPage(segments[2])) {
    return { route: "stepTemplatesId" };
  }

  if (segments[1] === "products" && isIdPage(segments[2])) {
    return { route: "productsId" };
  }

  if (segments[1] === "kitchenAppliances" && isIdPage(segments[2])) {
    return { route: "kitchenAppliancesId" };
  }

  if (segments[1] === "promocodes" && isIdPage(segments[2])) {
    return { route: "promocodesId" };
  }

  if (segments[1] === "recipeFilters" && isIdPage(segments[2])) {
    return { route: "recipeFiltersId" };
  }

  if (segments[1] === "surveys" && isIdPage(segments[2])) {
    return { route: "surveysId" };
  }

  if (segments[1] === "questionnaireViews" && isIdPage(segments[2])) {
    return { route: "questionnaireViewsId" };
  }

  if (segments[1] === "accounts" && isIdPage(segments[2])) {
    return { route: "accountsId" };
  }

  if (
    segments[1] === "accounts" &&
    segments[2] === "owned" &&
    isIdPage(segments[3])
  ) {
    return { route: "ownedAccounts" };
  }

  if (segments[1] === "myAccount") {
    return { route: "myAccount" };
  }

  if (segments.length > 2 && segments[1] === "ingredientTags") {
    return { route: "ingredientTagsId" };
  }

  if (segments[1] === "ingredientTags") {
    return { route: "ingredientTags" };
  }

  if (segments[1] === "nutrition") {
    return { route: "nutrition" };
  }

  return { route: segments[1] };
};

export const getURLParameters = (query) => {
  const queryWithoutEmptyParams = _.omitBy(
    query,
    (val) => val == null || val === ""
  );
  return new URLSearchParams(queryWithoutEmptyParams);
};

export const convertToArray = (val, fallback = []) => {
  if (typeof val === "string" && val === "") {
    return undefined;
  }
  return typeof val === "string" ? val.split(",") : fallback;
};

export const mapMeasurementsByLang = (measurements = [], lang) =>
  measurements
    .map(({ id, value }) => ({ id, value: value[lang] ?? "n/a" }))
    .sort((a, b) => a.value.localeCompare(b.value));

export const mapModularisationData = (data, usedIngredientIds) => {
  const header = get(data, "matrices[0].data.header", undefined);

  if (!header) {
    return undefined;
  }
  const conversions = data?.unitConversions ?? [];
  const headerList = header.split(";");

  return usedIngredientIds.reduce((array, id) => {
    const suggestions = headerList.reduce((suggestions, entry) => {
      if (entry.startsWith(`${id}_`) && !entry.endsWith("_del")) {
        const exchangeId = entry.split("_")[1];
        const conversion = conversions.find(
          (e) => e.source === id && e.target === exchangeId
        )?.unit;

        suggestions.push({ id: exchangeId, conversion: conversion });
      }
      return suggestions;
    }, []);
    array.push({
      id,
      suggestions,
    });
    return array;
  }, []);
};

export const download = (response, filename, type) => {
  var file = new Blob([response.body], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

export const normalisedTimestamp = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const reconstructed = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;

  return Date.parse(reconstructed);
};

export const getStatusColor = (status) => {
  switch (status) {
    case "Active":
    case "Lifetime":
      return "text-active border-active";
    case "Active Cancelled":
    case "Draft":
    case "Yearly":
      return "text-draft border-draft";
    case "Scheduled":
    case "Unknown":
      return "text-scheduled border-scheduled";
    case "Churned":
    case "None":
      return "text-ended border-ended";
    default:
      return "text-primary-100 border-primary-100";
  }
};

export const getQueryParams = (searchParams) => ({
  ...Object.fromEntries(searchParams.entries()),
});

export function setCreationCookie(title) {
  const toast = JSON.stringify({
    msg: `${title} Created`,
    status: 200,
    id: Date.now(),
  });

  return {
    headers: {
      "Set-Cookie": `toast=${toast}`,
    },
  };
}

export const getImageUrl = (url) => `${url}?kptnkey=${config.KPTN_KEY}`;

const createFlash = async (session, data) => {
  session.flash("toast", data);
  return {
    headers: {
      "Set-Cookie": await commitSession(session),
    },
  };
};

export const setCookieAndReturn = async (session, data) => {
  const flash = await createFlash(session, data);
  return json(null, flash);
};

export const getIsActionReload = (navigation) =>
  (navigation.state === "loading" &&
    navigation.formMethod != null &&
    navigation.formMethod != "GET" &&
    navigation.formAction === navigation.location?.pathname) ||
  navigation.data != null;

export const getIsActionRedirect = (navigation) =>
  (navigation.state === "loading" &&
    navigation.formMethod != null &&
    navigation.formMethod != "GET" &&
    navigation.formAction !== navigation.location?.pathname) ||
  navigation.data != null;

export const getErrorMessage = (error) => {
  if (error.data) {
    return error.data;
  }
  let message;
  switch (error.status) {
    case 400:
      message = "400: Bad Request Error";
      break;
    case 401:
      message = "403: Forbidden";
      break;
    case 404:
      message = "404: Page Not Found.";
      break;
    case 500:
      message = "500: Server Issue";
      break;
    default:
      message = `${error.data || error.status}`;
  }

  return message;
};

export function logRequest(error) {
  try {
    if (error.request) {
      console.error(`> REQUEST:`);
      console.error(`>   URL: ${error.response.request.path}`);
      console.error(`>   Method: ${error.response.request.method}`);
      console.error(`>   Headers:`);
      for (const [name, value] of Object.entries(
        error.response.request.getHeaders()
      )) {
        console.error(`>     ${name}: ${value}`);
      }
      if (error.response.request.body) {
        console.error(`>   Body:`);
        console.error(error.response.request.body.replace(/^/gm, "<      "));
      }
    }

    if (error.response) {
      console.error(`< RESPONSE:`);
      console.error(`<   Status: ${error.response.status}`);
      console.error(`<   Status Text: ${error.response.statusText}`);
      console.error(`<   Headers:`);
      for (const [name, value] of Object.entries(error.response.headers)) {
        console.error(`<     ${name}: ${value}`);
      }
      if (error.response.data) {
        console.error(`<   Body:`);
        console.error(
          JSON.stringify(error.response.data, null, 2).replace(/^/gm, "<      ")
        );
      }
    }

    if (!error.request && !error.response) {
      // just in case the error isn't coming from Axios.
      console.error(error);
    }
  } catch (ex) {
    console.error(error);
  }
}

export const fetchAllPages = async (request, ep, location = "results") => {
  let data = [];
  let pageCount = 1;

  // ep is paginated. getting all pages of data
  while (true) {
    const path = `${ep}?page=${pageCount}`;
    const res = await getData(path, request);

    data.push(...res?.[location]);

    if (data.length >= res.total) {
      break;
    }

    pageCount++;
  }

  return data;
};

export const handleLoaderError = (result) => {
  throw new Response(`Parsing Issue: ${result.error}`, {
    status: 500,
  });
};

export const filterItems = (first, second, value) => {
  const comparator = (first, second) => first[value] === second[value];
  return _.differenceWith(first, second, comparator);
};

export function parseState(state) {
  return state === "published" ? "Active" : "Draft";
}
